//  小饶封装的公共JS 和请求头函数
import md5 from 'js-md5' // 引入md5依赖
import store from '@/store'

let version = 'v1'
let signStr = '1001' // 渠道
let xsend = 2 // 国内1 国外2
let time = new Date().valueOf()

const GlobalState = {
  // 当前请求的版本号  时间戳  还有md5   uuid
  VersionEvent() {
    return version
  },
  TimeEvent() {
    return time
  },
  SignCodeEvent() {
    let sign = md5(`${version}-${time}-px`)
    return sign
  }, // 无需令牌类version,time,px；例如验证码
  SignEvent() {
    let loginInfo = store.state.user.loginInfo
    // let sign=md5(version+"-"+time+"-"+"px");
    let sign = md5(`${loginInfo.phone}-${time}-${loginInfo.pwd}-px`)
    // ;
    // 
    return sign
  }, // 无需令牌类user,time,pwd/code,px；注册/登录
  SignHaveUid() {
    let userInfo = JSON.parse(localStorage.getItem('user-info'))
    let sign = md5(
      `${userInfo[1].data.player.uid}-${time}-${userInfo[0].data.token}-px`
    )
    return sign
  }, // 有令牌访问uid,time,tiken,px
  //  将时间戳(毫秒) 转为年-月-日格式
  timestampToTime(timestamp) {
    let date = new Date() //时间戳为10位需*1000，时间戳为13位的话不需乘1000
    let Y = date.getFullYear() + '-'
    let M =
      (date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1) + '-'
    let D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
    let h =
      (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
    let m =
      (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) +
      ':'
    let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()

    let strDate = Y + M + D + h + m + s
    return strDate
  },
  // 获取当前用户Uid - 小饶
  UIDEvent() {
    // let Uid = '' || JSON.parse(localStorage.getItem('user-info'))[0].data.info.id
    let Uid = ''
    return Uid
  },
  //  返回本地存储的个人信息 - 小饶
  PersonalEvnet() {
    return JSON.parse(localStorage.getItem('user-info')) //  读取本地存储的个人信息
  },
  signStrFunc() {
    // 固定编号
    return signStr
  },
  getXsend() {
    return xsend
  },
}
export default GlobalState
