<template>
  <van-popup v-model="showPicker" round position="bottom">
    <van-picker show-toolbar :value-key="valueKey" :confirm-button-text="$t('common.confirm')" :cancel-button-text="$t('common.cancel')" :default-index="defaultIndex" :columns="data"
      @cancel="showPicker = false" @confirm="handleConfirm" />
  </van-popup>
</template>

<script>
import { Picker, Popup } from 'vant';
export default {
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup
  },
  props: {
    data: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      default: "label"
    },
    valueKey: {
      type: String,
      default: "value"
    },
    modelValue: {
      type: String,
      default: ""
    },
    cancelText: {
      type: String,
      default: 'btn.cancel'
    },
    confirmText: {
      type: String,
      default: 'btn.confirm'
    }
  },
  data() {
    return {
      // value: [],
      indicatorStyle: 'height: 50px;',
      tempObject: {},
      showPicker: false,
      defaultValue: '',
      defaultIndex: 0
    };
  },
  created() {
  },
  methods: {
    handleClose() {
      this.tempObject = {};
      this.showPicker = false
    },
    setValue(val) {
      this.value = [val];
    },
    open() {
      const index = this.data.findIndex(item => item[this.valueKey] === this.modelValue)
      this.defaultIndex = index;
      this.showPicker = true
    },
    handleConfirm(item) {
      this.tempObject = item
      this.$emit("update:modelValue", item[this.valueKey]);
      this.$emit("confirm", JSON.stringify(this.tempObject) !== '{}' ? this.tempObject : item);
      this.handleClose();
    }
  }
};
</script>
<style lang="less" scoped>
.picker-view {
  height: 250px;
  margin-top: 10px;

}

/deep/ .uni-picker-view-indicator {
  height: 34px !important;

}

.item {
  height: 34px !important;
  line-height: 34px;
  text-align: center;
  color: var(--theme);
  font-size: 12px;

}

.areacode_text {
  color: var(--light);
}

.popup_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--btnColor);
  height: 41px;

  .btn {
    line-height: 41px;
    padding: 0 20px;
    font-size: 12px;
  }

  .cancel_btn {
    .btn();
    color: var(--plain);
  }

  .confirm_btn {
    .btn();
    // color: #2979ff;
    color: var(--gray1Color);
  }
}

/deep/ .picker-view {
  margin-top: 0;

  .uni-picker-view-content {
    background: var(--light);
  }
}

.mask {
  background: var(--picker-mask);
  // background: red;
  background-position: top, bottom;
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.picker-view {
  background-color: var(--plain);
}

:deep(.uni-picker-view-indicator) {
  background-color: var(--pickerColor);
  opacity: 0.3;
}
</style>