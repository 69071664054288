<template>
	<div>
		<div class="areacode_text" @click="openPopup">
			<slot>
				+{{ modelValue }}
			</slot>
		</div>
		<Picker ref="popupRef" valueKey="areaCode" :data="columns" @confirm="handleConfirm" :modelValue="modelValue">
			<template v-slot:pickerItem="{ item }">
				{{ '+' + item.areaCode + ' ' + item.en }}
			</template>
		</Picker>
	</div>
</template>

<script>
import columns from "./data.json";
import verConfig from '@/utils/ver';
import Picker from '../picker.vue';

export default {
	data() {
		return {
			modelValue: verConfig.areaCode ? String(verConfig.areaCode) : "1",
			columns: columns
		};
	},
	components: {
		Picker
	},
	created() {
	},
	methods: {
		openPopup() {
			this.$refs.popupRef.open();
		},
		handleConfirm(val) {
			if (val.areaCode) {
				this.$emit("update:modelValue", val.areaCode);
				this.modelValue = val.areaCode
			}
		}
	}
}
</script>

<style lang="less"></style>